<template>
  <div v-if="user" class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Employees</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">Home</li>
              <li class="breadcrumb-item active">Employees</li>
            </ol>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>

    <!-- Main content -->
    <section class="content">
      <!-- Default box -->
      <div class="card card-solid" style="margin-bottom: 0 !important;">
        <Loader v-if="loading" style="height: 100%"  />
        <div class="card-header">
          <div class="col-sm-12 col-md-12">
          <div class="card-tools">
            <div class="input-group mb-0">
              <div class="input-group-prepend">
                <button title="Search By" type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                  {{this.form.by_value}}
                </button>
                <button title="Sort A-Z" v-if="form.sort_value!='asc'" @click.prevent="setSort('asc')" type="button" class="btn btn-default"><i class="fa fa-sort-alpha-desc"></i></button>
                <button title="Sort Z-A (Descending)" v-else @click.prevent="setSort('desc')" type="button" class="btn btn-default"><i class="fa fa-sort-alpha-asc"></i></button>
                <ul class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 48px, 0px);">
                  <li class="dropdown-item ddown" @click="setBy('Name')">Name</li>
                  <li class="dropdown-divider"></li>
                  <li class="dropdown-item ddown" @click="setBy('Email')">Email</li>
                  <li class="dropdown-item ddown" @click="setBy('Position')">Position</li>
                  <li class="dropdown-item ddown" @click="setBy('Role')">Role</li>
                </ul>
              </div>
              <input @keydown.enter="getResults" v-model="form.search_value" type="text" class="form-control" :placeholder="('Search employee by ' + this.form.by_value)">
              <div class="input-group-append">
                <!--<div class="btn btn-primary"><i class="fas fa-search"></i></div>-->
                <button title="Search" @click.prevent="getResults" type="button" class="btn btn-default"><i v-if="loading" class="spinner-border spinner-border-sm"></i><i v-else class="fa fa-search"></i></button>
              </div>
            </div>
          </div>
          </div>
          <!-- /.card-tools -->
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12 col-md-6 d-none d-sm-block">
              <cite class="text-orange"> Criteria: Search {{this.form.search_value}} from the "{{this.form.by_value}}" list in "{{form.sort_value}}" order.</cite>
            </div>
            <div class="col-sm-12 col-md-6">
              <h3 class="card-title float-right">Page {{ users.current_page }} of {{ users.last_page }} ({{ users.total }} results)</h3>
            </div>
          </div>
        </div>
        <div v-if="!users.data || loading" class="card-body pb-0 pt-0">
          <div class="row d-flex align-items-stretch">
            <div v-for="index in 9" :key="index" class="col-12 col-sm-6 col-md-4">
              <div class="card">
                <div class="card-header text-muted border-bottom-0">
                  <skeleton-loader-vue v-show="skeleton" class="" type="rect" :rounded="true" :width="150" :height="24" animation="fade" />
                </div>
                <div class="card-body pt-0">
                  <div class="row">
                    <div class="col-8">
                      <skeleton-loader-vue v-show="skeleton" class="mb-3" type="rect" :rounded="true" :width="220" :height="25" animation="fade" />
                      <skeleton-loader-vue v-show="skeleton" class="mb-2" type="rect" :rounded="true" :width="200" :height="17" animation="fade" />
                      <skeleton-loader-vue v-show="skeleton" class="mb-2" type="rect" :rounded="true" :width="200" :height="17" animation="fade" />
                    </div>
                    <div class="col-4 text-center">
                      <skeleton-loader-vue v-show="skeleton" class="mb-2" type="circle" :rounded="true" :width="86" :height="86" animation="fade" />
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="row">
                    <div class="text-left col-6">
                    </div>
                    <div class="text-right col-6">
                      <button disabled type="button" class="btn btn-xs btn-secondary">
                        <i class="fa fa-pencil-square-o"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="card-body pb-0 pt-0">
          <div v-show="users.total==0" class="card-body p-0">
            <div class="row justify-content-center mt-5">
                <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                  <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
                    <ellipse fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                    <g fill-rule="nonzero" stroke="#D9D9D9">
                      <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                      <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#FAFAFA"></path>
                    </g>
                  </g>
                </svg>                  
            </div>
            <div class="row justify-content-center mb-5 text-muted">
              <p>No records found. Please try another search.</p>
            </div>
          </div>
  
          <div class="row d-flex align-items-stretch">          
            <div v-for="xuser in users.data" :key="xuser.id" class="col-12 col-sm-6 col-md-4">
              <div class="card">
                <div class="card-header text-muted border-bottom-0">
                  {{xuser.position}}
                </div>
                <div class="card-body pt-0">
                  <div class="row">
                    <div class="col-7">
                      <h2 class="lead"><b>{{xuser.name}}</b></h2>
                      <p class="text-muted text-sm mb-1"><b>Email:</b> {{xuser.email}}</p>
                      <p class="text-muted text-sm"><b>Role: </b> {{xuser.role}} </p>
                    </div>
                    <div class="col-5 text-center">
                      <img :src="$profile_picture_baseurl + xuser.photo" width="90px" alt="" class="img-circle img-fluid">
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="row">
                    <div class="text-left col-6">
                      <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                        <input type="checkbox" class="custom-control-input" @click="setUserStatus(xuser.id, xuser.status, $event)" v-bind:id="xuser.id + '_StatusSwitch_'" :checked="xuser.status"  />
                        <label class="custom-control-label" v-bind:for="xuser.id + '_StatusSwitch_'">
                          <span v-if="xuser.status == 1">Active</span>
                          <span v-else>Inactive</span>
                        </label>
                      </div>
                      
                    </div>
                    <div class="text-right col-6">
                      <button @click="editEmployee(xuser)" type="button" class="btn btn-xs btn-primary">
                        <i class="fa fa-pencil-square-o"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
        <div v-show="users.total!=0" class="card-footer">
          <pagination class="pagination justify-content-center m-0" :data="users" @pagination-change-page="getResults" show-disabled :limit="2"></pagination>
        </div>
      </div>
      <br/>

      <!-- employee modal -->
      <div class="modal fade" data-backdrop="static" id="employeemodal" tabindex="-1" role="dialog" aria-labelledby="employeeModalLabel1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md" role="document">
          <div class="modal-content">
            <div class="modal-header bg-orange">
              <h1 class="card-title modal-title"><b>Edit Employee</b></h1>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <form @submit.prevent="updateEmployee()" @keydown="form.onKeydown($event)" novalidate>

            <div class="modal-body">
              <div class="row-aligncenter">
                <div class="card-body table-responsive p-0">

                  <div class="form-group" :class="{'has-error':$v.form.name.$error,'has-success':!$v.form.name.$invalid }">
                    <label for="inputName">Employee Name</label><span class="text-danger">*</span>
                    <input @keydown.enter="focusNext" type="text"  v-model.lazy="$v.form.name.$model" class="form-control" id="inputName" aria-describedby="inputFirstname" :class="{'is-invalid':$v.form.name.$error,'is-valid':!$v.form.name.$invalid }" placeholder="Employee name" autocomplete="off" />
                    <div class="invalid-feedback">
                        <span class="help-block" v-if="!$v.form.name.required"><i class="fa fa-exclamation-circle"></i> Employee name is required.</span>
                        <span class="help-block" v-if="!$v.form.name.minLength"><i class="fa fa-exclamation-circle"></i> Employee name must have at least {{$v.form.name.$params.minLength.min}} characters.</span>
                        <span class="help-block" v-if="!$v.form.name.maxLength"><i class="fa fa-exclamation-circle"></i> Employee name must not exeed to {{$v.form.name.$params.maxLength.max}} characters.</span>
                    </div>
                  </div>

                  <div class="form-group" :class="{'has-error':$v.form.position.$error,'has-success':!$v.form.position.$invalid }">
                    <label for="inputPosition">Position</label><span class="text-danger">*</span>
                    <input @keydown.enter="focusNext" type="text"  v-model.lazy="$v.form.position.$model" class="form-control" id="inputPosition" aria-describedby="inputPosition" :class="{'is-invalid':$v.form.position.$error,'is-valid':!$v.form.position.$invalid }" placeholder="Position" autocomplete="off" />
                    <div class="invalid-feedback">
                        <span class="help-block" v-if="!$v.form.position.required"><i class="fa fa-exclamation-circle"></i> Position is required.</span>
                        <span class="help-block" v-if="!$v.form.position.minLength"><i class="fa fa-exclamation-circle"></i> Position must have at least {{$v.form.position.$params.minLength.min}} characters.</span>
                        <span class="help-block" v-if="!$v.form.position.maxLength"><i class="fa fa-exclamation-circle"></i> Position must not exeed to {{$v.form.position.$params.maxLength.max}} characters.</span>
                    </div>
                  </div>

                  <div class="form-group" :class="{'has-error':$v.form.role.$error,'has-success':!$v.form.role.$invalid }">
                    <label for="inputRole">Role</label><span class="text-danger">*</span>
                    <select  v-model.lazy="$v.form.role.$model" :class="{'is-invalid':$v.form.role.$error,'is-valid':!$v.form.role.$invalid }" class="form-control" id="inputRole" aria-describedby="inputRole">
                      <option value="">- Select role -</option>
                      <option value="Admin">Admin</option>
                      <option value="Cashier">Cashier</option>
                      <option value="Reader">Reader</option>
                      <option value="Restricted">Restricted</option>
                      <option value="Supervisor">Supervisor</option>
                    </select>
                    <div class="invalid-feedback">
                      <span class="help-block" v-if="!$v.form.role.required"><i class="fa fa-exclamation-circle"></i> Role is required.</span>
                    </div>
                  </div>


                </div>
             
              </div>
            </div>
            <div class="card-footer">
              <div class="row col-12 p-0 m-0">
                  <div class="col-8 p-0">
                    
                  </div>
                  <div class="text-right col-12 p-0">
                    
                    <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>&nbsp;
                    <button type="submit" :disabled="$v.$invalid" class="btn btn-info" ><i class="fa fa-chevron-circle-right"></i> Save</button>
                  </div>
              </div>
            </div>

            </form>


          </div>
        </div>
      </div>

    </section>

 
  </div>
</template>

<script>
import axios from 'axios'
import {mapGetters} from 'vuex'
import Form from 'vform'
import Loader from '@/components/Loader'
import { required, minLength, maxLength, sameAs, not } from 'vuelidate/lib/validators'
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
  name: 'Employees',

  components: {
    Loader, VueSkeletonLoader,
  },

  data() {
    return {
      avatarbase: null,
      loading: false,
      skeleton: true,
      users : {},
      isloaded: false,
      error: '',
      form: new Form({
        id: '',
        name: '',
        position: '',
        role: '',
        by_value: 'Name',
        sort_value: 'asc',
        search_value: '',
      })
    }
  },

  validations: {
    form: {
      name: { required,  minLength: minLength(2),  maxLength: maxLength(60) },
      position: { required,  minLength: minLength(2),  maxLength: maxLength(60) },
      role: { required },
    }
  },

  methods: {

    focusNext(e) {
      const inputs = Array.from(e.target.form.querySelectorAll('input'));
      const index = inputs.indexOf(e.target);

      if (index < inputs.length) {
          inputs[index + 1].focus();
      }
    },

    setSort(val) {
      this.form.sort_value = val;
      this.getResults();
    },

    setBy(val) {
      this.form.by_value = val;
      this.getResults();
    },

    editEmployee(employee) {
      this.editmode = true;
      this.form.reset();
      this.form.clear();
      this.form.fill(employee);
      $('#employeemodal').modal('show');
    },

    updateEmployee() {
      this.$v.$touch();
      this.$Progress.start();
      if(!this.$v.$invalid) {
        try {
          
          this.form.put('api/users/' + this.form.id)
          .then(()=>{
                this.$swal.fire({
                  icon: 'success',
                  title: 'Employee updated'
                })
                this.$Progress.finish();
                this.loadData();
                $('#employeemodal').modal('hide')
          }).catch(()=>{
                this.$swal.fire({
                  icon: 'error',
                  title: 'Employee update failed'
                });
                this.$Progress.fail();
          })
        } catch (e) {
            this.error = e.response.data.message;
            this.$Progress.fail();
        }
      }
      this.$v.$reset();
    },

    async getResults(page = 1) {
      this.loading = true;
      let query = this.form.search_value;
      let sort = this.form.sort_value;
      let field = this.form.by_value;
      let by = '';

      if (field === 'Name') {
        by = 'name'
      } else if (field === 'Email') {
        by = 'email'
      } else if (field === 'Role') {
        by = 'role'
      } else if (field === 'Position') {
        by = 'position'
      }
      
      if(query == '') {
        this.loading = true;
        await axios.get('api/users/filter?sort=' + sort + '&by=' + by +'&page=' + page).then(response => { this.users = response.data; });
      } else {
        await axios.get('api/users/filter?sort=' + sort + '&search=' + query + '&by=' + by +'&page=' + page).then(response => { this.users = response.data; }); 
      }
      this.loading = false;
    },

    async setUserStatus(id, status) {
      this.$Progress.start();
      await axios.put('api/users/' + id +  '/status/' + status)
      .then(()=>{
        this.$swal.fire({
          icon: 'success',
          title: 'User status updated'
        })
        this.$Progress.finish();
        this.loadUserData();
      }).catch(()=>{
        this.$swal.fire({
          icon: 'error',
          title: 'User update failed'
        });
        this.$Progress.fail();
      })
    },

    async loadUserData() {
      this.$Progress.start();
      await axios.get('api/users').then(({ data }) => (this.users = data));
      this.$Progress.finish();
    },

    async loadData() {
      this.loading = true;
      await axios.get('api/users').then(({ data }) => (this.users = data));
      this.loading = false;
    }

  },

  computed: {
    ...mapGetters(['user']),
  },

  mounted() {
    this.$Progress.start();
    this.avatar = localStorage.getItem("avatar")
    this.loadData();
    this.$Progress.finish();
  }

}
</script>


<style scoped>
.pagination {
  margin: 0;
}

.ddown {
  cursor: pointer;
}

</style>
